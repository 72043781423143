// 新增爱豆
<template>
  <div class="main">
    <van-dialog
      v-model="isShow"
      :title="isTitle"
      :showConfirmButton="false"
      class="dialog_box"
      width="78%"
    >
      <div class="modal_main">
        <div class="modal_box">
          <input
            type="text"
            v-model="star_name"
            class="ipt"
            @input="handleChange"
            placeholder="请输入爱豆姓名"
          />

          <van-button
            class="default_btn mgt20"
            @click="onSubmit"
            :disabled="!isDisabled"
            color="#4BFFF2"
            round
            >提交
          </van-button>
          <img
            class="close_img"
            @click="onCancel"
            src="./../../assets/images/icon_close_b.png"
            alt=""
          />
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "AddIdo",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      star_name: "",
      isDisabled: false,
    };
  },
  mounted() {},
  methods: {
    // 新增爱豆
    onSubmit() {
      this.$emit("addStar", this.star_name);
      this.onCancel();
    },
    onCancel() {
      this.$emit("update:isShow", false);
      this.star_name = "";
      this.isDisabled = false;
    },

    handleChange() {
      this.isDisabled = this.star_name.length > 0;
    },
  },
};
</script>

<style lang="less" scoped>
.dialog_box {
  background-color: transparent;
}
.main {
  /deep/ .van-dialog {
    overflow: inherit;
  }
}
.modal_main {
  position: relative;
  min-height: 240px;
  background: url("./../../assets/images/ido/back_add.png") no-repeat 100% 100%;
  background-size: cover;
  .modal_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 25px;
    padding-top: 40%;
    .ipt {
      box-sizing: border-box;
      width: 100%;
      background: #5257cf;
      border: 1px solid #6f87ff;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
    }
    .default_btn {
      width: 80%;
      height: 37px;

      .van-button__text {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #111111;
        line-height: 20px;
      }
    }
    .close_img {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -60px;
    }
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #6f87ff;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #6f87ff;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #6f87ff;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #6f87ff;
  }
  input[type="text"] {
    padding-top: 4px;
    -webkit-appearance: none;
  }
  textarea {
    -webkit-appearance: none;
  }
}
</style>