<template>
  <div class="choose_ido">
    <!-- <i-nav-bar :iTitle="title"></i-nav-bar> -->
    <!--  主体部分 -->
    <div class="main">
      <div class="all_ido">
        <div class="ido_item" v-for="(item, index) in starList" :key="index">
          <div class="item_header" v-if="item.star_data.length > 0">
            <img
              class="header_img"
              :src="
                require(`./../../assets/images/ido/pic_title_img${item.type}.png`)
              "
              alt=""
            />
          </div>
          <div class="item_body" v-if="item.star_data.length > 0">
            <div class="star_list">
              <div
                v-for="(item, index) in item.star_data"
                :key="index"
                :class="index < tagStarListLength ? 'star_item' : ''"
                @click="onSelect(item)"
              >
                <!-- box_border -->
                <div
                  :class="`img_box ${
                    isSelectedId == item.star_id ? 'box_border' : ''
                  }`"
                  v-show="index < tagStarListLength"
                >
                  <img class="star_img" :src="item.img_url" alt="" />
                  <!-- :src="item.img_url" -->
                </div>
                <!-- txt_c -->
                <div
                  :class="`star_txt ${
                    isSelectedId == item.star_id ? 'txt_c' : ''
                  }`"
                  v-if="index < tagStarListLength"
                >
                  {{ item.star_name }}
                </div>
              </div>

              <div
                class="star_item add_box"
                v-if="item.star_data.length > 5"
                @click="showMore(item.star_data)"
              >
                <!-- box_border -->
                <div class="img_box">
                  <img
                    v-if="isShowMoreBtn"
                    class="icon_img"
                    src="./../../assets/images/ido/icon_pack.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="icon_img"
                    src="./../../assets/images/ido/icon_more.png"
                    alt=""
                  />
                </div>
                <div class="star_txt txt_c">
                  {{ isShowMoreBtn ? "收起" : "更多" }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 新增 -->
        <div class="ido_item">
          <div class="item_header">
            <img
              class="header_img"
              src="./../../assets/images/ido/pic_add.png"
              alt=""
            />
          </div>
          <div class="item_body">
            <div class="star_list">
              <div
                class="star_item"
                @click="onSelect(addIdoForm)"
                v-if="addIdoForm.star_name"
              >
                <!-- box_border -->
                <div
                  :class="`img_box ${
                    isSelectedId == addIdoForm.star_id ? 'box_border' : ''
                  }`"
                >
                  <img
                    class="star_img"
                    src="./../../assets/images/ido/pic_star_default.png"
                    alt=""
                  />
                  <!-- :src="item.img_url" -->
                </div>
                <div class="star_txt">{{ addIdoForm.star_name }}</div>
              </div>

              <div class="star_item add_box" @click="openModal">
                <!-- box_border -->
                <div class="img_box">
                  <img
                    class="icon_img"
                    src="./../../assets/images/ido/icon_add.png"
                    alt=""
                  />
                  <!-- :src="item.img_url" -->
                </div>
                <div class="star_txt txt_c">新增</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-ido :isShow.sync="isOpenModal" @addStar="addStar"></add-ido>

    <div class="safe_distance"></div>
    <!--  底部按钮  -->
    <div class="footer">
      <van-button class="footer_btn btn1" color="#111111" round @click="goBack">
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_left.png"
          alt=""
        />
      </van-button>
      <van-button
        v-if="isDisabled"
        class="footer_btn btn2 mgl10"
        @click="toNext"
        color="#4BFFF2"
        round
        >继续
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_right.png"
          alt=""
        />
      </van-button>
      <van-button
        v-else
        class="footer_btn btn3 mgl10"
        disabled
        color="#4BFFF2"
        round
        >请选择爱豆
      </van-button>
    </div>
  </div>
</template>

<script>
import iNavBar from "@/components/iNavBar.vue";
import AddIdo from "./AddIdo.vue";
import { getStarData } from "@/request/api";
import { judgeImgEffective } from "@/utils/utils";
export default {
  name: "ChooseIdo",
  data() {
    return {
      judgeImgEffective,
      title: "选择爱豆",
      starList: [],
      allStarList: [],
      isDisabled: false,
      isShowMoreBtn: false,
      isSelected: false,
      tagStarListLength: 4,
      isSelectedList: [],
      isOpenModal: false,
      isSelectedId: 0,
      addIdoForm: {
        star_id: 9999,
        star_name: "",
      },
    };
  },
  components: {
    iNavBar,
    AddIdo,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getData();
      let localObj = JSON.parse(
        window.localStorage.getItem("submitInfo")
      )?.starObj;
      this.isSelectedId = localObj ? localObj.star_id : 0;
      this.isSelectedList = localObj ? [localObj] : [];
      this.isDisabled = !!localObj;
    },
    // 获取数据
    async getData() {
      let data = await getStarData();
      console.log("获取到的爱豆信息", data);
      if (data.rs_code == 1000) {
        // this.starList = data.details || [];
        this.$set(this, "starList", data.details || []);
        this.$forceUpdate();
        // 1：人气偶像；2：实力网红；3：校园神仙声音
        this.allStarList = this.getAllStarList(data.details);
        console.log("所有明星", this.allStarList);
      }
    },

    // 数组拍平
    getAllStarList(arr) {
      let newArr = [];
      arr.forEach((item1) => {
        item1.star_data.forEach((item2) => {
          let obj = {
            isSelected: false,
            ...item2,
          };
          newArr.push(obj);
        });
      });
      return newArr;
    },

    // 打开弹窗
    openModal() {
      this.isOpenModal = true;
    },

    // 选中的明星
    onSelect(obj) {
      // let bol = this.isSelectedList.some((item) => item.star_id == obj.star_id);
      // if (bol) {
      //   this.isSelectedList = this.isSelectedList.filter(
      //     (item) => item.star_id != obj.star_id
      //   );
      //   this.isDisabled = this.isSelectedList.length > 0;
      //   this.$set(obj, "isSelected", false);
      //   return false;
      // } else {
      //   this.isSelectedList.push(obj);
      //   this.isDisabled = this.isSelectedList.length > 0;
      //   this.$set(obj, "isSelected", true);
      //   return true;
      // }
      this.isSelectedList = [];
      if (this.isSelectedId != obj.star_id) {
        this.isSelectedList.push(obj);
      }
      this.isSelectedId = this.isSelectedId == obj.star_id ? 0 : obj.star_id;
      this.isDisabled = this.isSelectedId != 0;
    },
    // 是否显示更多
    showMore(arr) {
      this.isShowMoreBtn = !this.isShowMoreBtn;
      this.tagStarListLength = this.isShowMoreBtn ? arr.length : 4;
    },
    // 新增ido
    addStar(val = "") {
      this.addIdoForm.star_name = val;
    },
    toNext() {
      LA.track('idolnextbtn_click');
      _hmt.push(["_trackEvent", "idolnextbtn_click", "click"]);
      console.log("选中的明星", this.isSelectedList);
      this.$router.push({
        name: "Scene",
      });
      let data = {
        starObj: this.isSelectedList[0],
      };
      window.localStorage.setItem("submitInfo", JSON.stringify(data));
      console.log("下一步");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.choose_ido {
  width: 100%;
  min-height: 100%;
  background: #5257cf;
  .main {
    margin-top: 36px;
    // padding-bottom: 50px;
    .all_ido {
      width: 100%;
      min-height: 100px;

      .ido_item {
        margin-top: 40px;
        .header_img {
          height: 34px;
          margin-left: 5px;
          object-fit: cover;
        }

        .item_body {
          padding: 0 20px;
          .star_list {
            display: flex;
            flex-flow: wrap;
            .star_item {
              box-sizing: border-box;
              width: 55px;
              margin-right: 15px;
              margin-top: 12px;
              &:nth-child(5n) {
                margin-right: 0;
              }
            }

            .add_box {
              margin-right: 0 !important;
            }
            .img_box {
              position: relative;
              width: 55px;
              height: 55px;
              border-radius: 50%;
              border: 2px solid #5257cf;
              background: #6f87ff;
              .star_img {
                position: absolute;
                width: 55px;
                height: 55px;
                border-radius: 50%;
                object-fit: contain;
              }
              .icon_img {
                position: absolute;
                width: 18px;
                height: 18px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
              }
            }
            .box_border {
              // box-sizing: border-box;
              border: 2px solid #4bfff2;
            }
            .txt_c {
              color: #4bfff2 !important;
            }
            .star_txt {
              margin-top: 6px;
              padding-left: 1px;
              font-size: 12px;
              font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
              font-weight: normal;
              color: #ffffff;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.safe_distance {
  height: 57px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}

.footer {
  box-sizing: border-box;
  border-top: 0.5px solid #484dc6;
  background-color: #5257cf;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 10;

  .footer_btn {
    width: 100%;
    height: 37px;

    .van-button__text {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #111111;
      line-height: 20px;
    }
  }
  .left_img {
    width: 22px;
    height: 10px;
    object-fit: contain;
  }
  .btn1 {
    position: relative;
    width: 120px;
    background: #111111;
    .van-button__text {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #4bfff2;
      line-height: 20px;
    }
  }
  .btn2 {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #111111;
  }
}
</style>